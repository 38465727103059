<template>
  <div class="serveBindingManage">
    <ds-header title="基础积分"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectId"
        >
          <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="积分类型：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.integralType"
        >
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="work_over_time">
            加班积分
          </a-select-option>
          <a-select-option value="work">
            上班积分
          </a-select-option>
          <a-select-option value="birth_over_time">
            生日积分
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.state"
        >
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="1">
            启用
          </a-select-option>
          <a-select-option value="0">
            禁用
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addPoints()"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="iconUrl" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.iconUrl" v-if="record.iconUrl"/>
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="amend(record)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="restart(record)">
            <span v-if="record.state == 0">启用</span>
            <span v-else>禁用</span>    
          </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import * as api from "@/api/pointsManagement";

export default {
  name: "pointsManagement",
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projectList;
      },
    }),
  },
  data() {
    return {
      searchForm: {
        integralType: "",
        projectId: undefined,
        state: "",
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "项目",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "积分类型",
            dataIndex: "integralType",
            key: "integralType",
            customRender(text) {
              return text == 'work' ? "上班积分" :  text === 'work_over_time' ? "加班积分" : '生日积分';
            },
          },
          {
            title: "积分额度",
            dataIndex: "ruleValue",
            key: "ruleValue",
          },
          {
            title: "用户人群",
            dataIndex: "userLabelList",
            key: "userLabelList",
          },
          {
            title: "企业类型",
            dataIndex: "enterpriseTypeList",
            key: "enterpriseTypeList",
          },
          {
            title: "状态",
            dataIndex: "state",
            key: "state",
            customRender(text) {
              return text == 0 ? "禁用" : "启用";
            },
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
          },
          {
            title: "操作人",
            dataIndex: "updateUserName",
            key: "updateUserName",
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  created() {
    this.searchFormList();
  },
  methods: {
    // 查看
    view(record) {
      var id = record.id;
      this.$router.push({
        path: "/integralMallManagement/pointsManagement/add",
        query: {
          id: id,
          type: "view",
        },
      });
    },

    // 编辑
    amend(record) {
      var id = record.id;
      this.$router.push({
        path: "/integralMallManagement/pointsManagement/add",
        query: {
          id: id,
          type: "update",
        },
      });
    },

    // 新增
    addPoints() {
      this.$router.push({
        path: "/integralMallManagement/pointsManagement/add",
        query: {
          type: "add",
        },
      });
    },

    // 启用禁用
    restart(record) {
      let that = this;
      if(record.state == 0) {
        var title = "确定启用该数据吗？"
        var status = 1;
      } else {
        var title = "确定禁用该数据吗？"
        var status = 0;
      }
      
      this.$confirm({
        title: title,
        onOk() {
          var data = {};
          data.id = record.id;
          data.state = status;
          api.pointsUpdateState(data).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error("操作失败");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    // 列表
    async searchFormList() {
      var data = {};
      if(this.searchForm.state) {
        data.state = this.searchForm.state
      }
      data.projectId = this.searchForm.projectId
      if(this.searchForm.integralType) {
        data.integralType = this.searchForm.integralType
      }
      data.pageNum = this.table.pagination.current;
      data.pageSize	 = this.table.pagination.pageSize
      const res = await api.pointsList(data);
      if (res.code == 200) {
        this.table.dataInfo = res.data.items;
        this.table.pagination.total = res.data.total;
      }
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.inputClass {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
